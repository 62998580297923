

import { defineComponent } from 'vue'
import wrap from 'classcat'

import { emitter } from '../main'

export default defineComponent({
  name: 'Key',
  props: {
    chroma: {
      type: Number,
      default: 0,
    },
    kind: {
      type: String,
      default: 'white',
    },
    ind: {
      type: Number,
      default: 0,
    },
    oct: {
      type: Number,
      default: 0,
    },
    pcset: {
      type: Object,
      default: () => {},
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    x: {
      type: Number,
      default: 0,
    },
    rx: {
      type: Number,
      default: 0,
    },
    ry: {
      type: Number,
      default: 0,
    },
    bpos: {
      type: Array,
      default: () => [],
    },
    whiteWidth: {
      type: Number,
      default: 40,
    },
    whiteHeight: {
      type: Number,
      default: 150,
    },
    blackWidth: {
      type: Number,
      default: 22,
    },
    blackHeight: {
      type: Number,
      default: 90,
    },
  },
  data () {
    return {
      active: false,
    }
  },
  computed: {
    className (): any {
      return this.getKeyTypes() + ' ' + (this.active ? 'active' : '')
    },
    midi (): number {
      return (this.oct + 1) * 12 + this.chroma
    },
    /*
    offset (): any {
      return this.isWhite()
        ? this.ind * this.whiteWidth
        : this.whiteWidth * this.bpos[this.ind] - this.blackWidth / 2
    },
    */
  },
  methods: {
    onClick (e: any): void {
      console.log('event', e.target)
    },
    isWhite (): boolean {
      return (this.kind === 'white')
    },
    getKeyWidth (): number {
      return this.isWhite() ? this.whiteWidth : this.blackWidth
    },
    getKeyHeight (): number {
      return this.isWhite() ? this.whiteHeight : this.blackHeight
    },
    getXoffset (): number {
      const bposInd:number = Number(this.bpos[this.ind])
      return this.isWhite()
        ? this.x + this.ind * this.whiteWidth
        : this.x + this.whiteWidth * bposInd - this.blackWidth / 2
    },
    pianoNoteOn (e: any): void {
      e.preventDefault()
      if (this.active === true) {
        this.active = false
      } else {
        this.active = false
      }
      emitter.emit('tonicChange', this.midi)
      // OLD Vue2
      // this.$root.$emit('tonicChange', this.midi)
    },
    getKeyTypes () {
      // console.log(this.notes)
      if (this.notes) {
        return wrap([
          'piano-key',
          this.kind,
          {
            active: this.notes.names.find((midi: number) => { return (midi === this.midi) }),
            tonics: this.pcset.tonic === this.chroma,
            tonic: this.notes.tonic === this.midi,
          },
        ])
      }
    },
  },
})
