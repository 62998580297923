
import { defineComponent } from 'vue'

// import anime from 'animejs's
// import color from 'onecolor'
import { Array, PcSet, Scale, Note, Chord } from 'tonal'
import * as Key from 'tonal-key'
import * as Range from 'tonal-range'
import { transpose } from 'tonal-distance'

import { emitter } from '../main'

import PianoKeyboard from '../components/PianoKeyboard.vue'

// RotoKeyboard
// import RotoKeyboard from '@/components/RotoKeyboard.vue'

import ChromaticWheel from '../components/ChromaticWheel.vue'

// Now it´s planned to be dynamic
// import colorList from '@/data/werner'

import { polarToCartesian } from '../util'

import Soundfont from 'soundfont-player'

import { instruments, buildScale, buildChord } from '../player'

const presets = {
  major: ['Maj7', 'm7', 'm7', 'Maj7', '7', 'm7', 'm7b5'],
  dorian: ['m7', 'm7', 'Maj7', '7', 'm7', 'm7b5', 'Maj7'],
  phrygian: ['m7', 'Maj7', '7', 'm7', 'm7b5', 'Maj7', 'm7'],
  lydian: ['Maj7', '7', 'm7', 'm7b5', 'Maj7', 'm7', 'm7'],
  mixolydian: ['7', 'm7', 'm7b5', 'Maj7', 'm7', 'm7', 'Maj7'],
  aeolian: ['m7', 'm7b5', 'Maj7', 'm7', 'm7', 'Maj7', '7'],
  locrian: ['m7b5', 'Maj7', 'm7', 'm7', 'Maj7', '7', 'm7'],
}

/*
let presetsAll = {
  'major': ['Maj7', 'm7', 'm7', 'Maj7', '7', 'm7', 'm7b5'],
  'dorian': ['m7', 'm7', 'Maj7', '7', 'm7', 'm7b5', 'Maj7'],
  'phrygian': ['m7', 'Maj7', '7', 'm7', 'm7b5', 'Maj7', 'm7'],
  'lydian': ['Maj7', '7', 'm7', 'm7b5', 'Maj7', 'm7', 'm7'],
  'mixolydian': ['7', 'm7', 'm7b5', 'Maj7', 'm7', 'm7', 'Maj7'],
  'minor': ['m7', 'm7b5', 'Maj7', 'm7', 'm7', 'Maj7', '7'],
  'locrian': ['m7b5', 'Maj7', 'm7', 'm7', 'Maj7', '7', 'm7'],
  'melodic minor': ['mMaj7', 'm7', 'M7#5', '7', '7', 'm7b5', 'm7b5'],
  'harmonic minor': ['mMaj7', 'm7b5', 'M7#5', 'm7', '7', 'Maj7', 'o7'],
  'harmonic major': ['Maj7', 'm7b5', 'm7', 'mMaj7', '7', 'M7#5', 'o7'],
}
*/

const ac = new AudioContext()

interface ScaleSet {
  tonic: string
  palette: object
  pianoClass: string
  rotoClass: string
  initial: string
  mode: string
  chordType: string,
  piano: null | any,
  instruments: string[]
  instrument: string
}

export default defineComponent({
  name: 'Scale',
  components: {
    ChromaticWheel,
    PianoKeyboard,
  },
  data () {
    return {
      tonic: '',
      palette: {},
      pianoClass: 'piano-keyboard',
      rotoClass: 'roto-keyboard',
      initial: 'C3',
      mode: 'major',
      chordType: '',
      piano: null,
      instruments: instruments(),
      instrument: instruments()[2],
    } as ScaleSet
  },
  computed: {
    presets (): any {
      return presets
    },
    midi (): any {
      return Note.midi(this.tonic)
    },
    pc (): any {
      return Note.pc(this.tonic)
    },
    oct (): any {
      return Note.oct(this.tonic)
    },
    note (): any {
      return Note.props(this.tonic)
    },
    pcset (): any {
      return {
        tonic: Note.chroma(this.tonic),
        chroma: PcSet.chroma(this.notes) || '',
      }
    },
    enharmonic (): any {
      return Note.enharmonic(this.tonic)
    },
    keyProps (): any {
      const tonic = Note.pc(this.tonic)
      let key
      if (this.mode.search('major') !== -1) key = 'major'
      if (this.mode.search('minor') !== -1) key = 'minor'
      const keyName = tonic + ' ' + this.mode
      this.logMe('keyName', keyName)
      this.logMe('key', key)
      this.logMe('mode', this.mode)
      return Key.props(keyName)
    },
    keyName (): any {
      return this.keyProps.name
    },
    keyChords (): any {
      return Key.chords(this.keyName)
    },
    allChords (): any {
      let scaleChord = Scale.chords(this.mode)
      scaleChord = this.removeFromArray(scaleChord, '5')
      scaleChord = this.removeFromArray(scaleChord, '4')
      scaleChord = this.removeFromArray(scaleChord, '69#11')
      return scaleChord
    },
    chords (): any {
      return this.getChord(this.presets[this.mode])
    },
    degrees (): any {
      return Key.degrees(this.keyName)
    },
    secDomChords (): any {
      return Key.secDomChords(this.keyName)
    },
    triads (): any {
      return Key.triads(this.keyName, [1, 2, 3, 4, 5, 6, 7])
    },
    relative (): any {
      let mode = ''
      if (this.mode === 'major') mode = 'minor'
      return Key.relative(mode, this.keyName)
    },
    intervals (): any {
      return Scale.intervals(this.mode)
    },
    alterations (): any {
      const alt = this.keyProps.alt
      const num = Math.abs(this.keyProps.alt)
      return alt > 0 ? num + '♯' : num + '♭'
    },
    alteredNotes (): any {
      return Key.alteredNotes(this.keyName)
    },
    altType (): any {
      const alt = this.keyProps.alt
      return alt > 0 ? '#' : 'b'
    },
    scale (): any {
      return Scale.notes(this.pc, this.mode)
    },
    midiScaleOct (): any {
      const ms = this.scale
      return ms.map((note: any) => {
        return Note.midi(note + this.oct)
      })
    },
    midiScale (): any {
      return this.midiScaleUp(this.scale)
    },
    notes (): any {
      return {
        tonic: this.midi,
        names: this.midiScale,
        rest: this.midiScale.filter((value: any) => {
          return value !== this.midi
        }),
      }
    },
    names (): any {
      return { scale: Note.names(' ' + this.altType) }
    },
    notesOct (): any {
      return {
        tonic: this.midi,
        names: this.midiScaleOct,
        rest: this.midiScaleOct.filter((value: any) => {
          return value !== this.midi
        }),
      }
    },
    scaleName (): any {
      return `${this.pc} ${this.mode}`
    },
    fifths (): any {
      return Range.fifths('C', [0, 11]).map((note: any) => {
        return Note.enharmonic(note)
      })
    },
    urlTonic (): any {
      return this.beauty(this.tonic)
    },
    relatives (): any[] {
      const rels: any[] = []
      // const scaleModes = Scale.modeNames(`${this.pc} ${this.mode}`)
      // this.logMe('scaleModes', scaleModes)
      Scale.modeNames(`${this.pc} ${this.mode}`).forEach((name) => {
        const beautyNote = this.beauty(name[0])
        const item = {
          name: `${name[1]}`,
          url: `${beautyNote}${this.oct}/${name[1]}`,
        }
        rels.push(item)
      })
      return rels
    },
  },
  watch: {
    mode (): any {
      this.$router.push({
        path: `/scale/${this.urlTonic}/${this.mode}`,
      })
    },
    /*
    instrument (): any {
      this.loadPiano(Soundfont, this.instument)
    },
    tonic () {
      this.$router.push({
        path: `/scale/${this.tonic}/${this.mode}`,
      })
    },
    */
    $route (r): any {
      this.getNoteNameAndSet(r.params.n, r.params.m)
    },
  },
  created () {
    emitter.on('tonicChange', (midi: any) => {
      this.handleTonicChange(midi)
    })
    /* OLD Vue2
    this.$root.$on('tonicChange', (midi) => {
      this.handleTonicChange(midi)
    })
    */
    this.getNoteNameAndSet(this.$route.params.n, this.$route.params.m)
    this.loadPiano(Soundfont)
    // this.getNoteNameAndSet(this.$route.query.n, this.$route.query.m)
  },
  mounted () {
    // @ts-ignore
    this.init()
    // OLD Vue2
    // this.$emit('init', this.init())
  },
  methods: {
    async init () {
      // this.getNoteNameAndSet(this.$route.query.n, this.$route.query.m)
      // this.logMe(Scale.modeNames('A harmonic minor'), '')
    },
    handleTonicChange (midi: any): any {
      const tonicTemp = Note.fromMidi(midi)
      const tonic = Note.pc(tonicTemp)
      const keyName = tonic + ' ' + this.mode
      const altNum = parseInt(Key.props(keyName).alt)
      let altType = false
      if (altNum >= 0 && altNum <= 5) {
        altType = true
      }
      if (altNum <= -7) {
        altType = true
      }
      if (altNum >= 7) {
        altType = false
      }
      this.tonic = Note.fromMidi(midi, altType)
      this.$router.push({
        path: `/scale/${this.urlTonic}/${this.mode}`,
      })
    },
    getNoteNameAndSet (note: any, mode: any): void {
      console.log('getNoteNameAndSet', note, mode)
      let subsNote
      if (note) {
        subsNote = note.replace('♯', '#').replace('♭', 'b')
        this.tonic = Note.name(subsNote)
      } else {
        this.tonic = Note.name(this.initial)
      }
      if (mode) {
        this.mode = mode
      }
      this.player()
    },
    logMe (tit: any, msg: any): void {
      console.log(tit, msg)
    },
    getNoteFreq (): number {
      return Math.round(this.note.freq * 100) / 100
    },
    getChord (chordArray: any[]): object {
      // let chords = Key.leadsheetSymbols(this.presets[this.mode])
      return chordArray.map((name, index) => {
        const chordName = this.scale[index] + name
        if (Chord.exists(chordName)) {
          const chordType = Chord.tokenize(chordName)[1]
          const intervals = Chord.props(chordType).intervals
          const chordNoteName = Note.props(Chord.notes(chordName)[0] + this.oct)
          const chordTonic = chordNoteName.midi
          const chordNotes = intervals.map((interval: string): any => {
            // return Note.midi(transpose(chordNoteName.name, interval)) + ''
            if (chordNoteName.name) {
              const transposed = transpose(chordNoteName.name, interval)
              return Note.midi(transposed.toString())
            }
          })
          /*
          let chordNotes = Chord.notes(chordName).map((name, index) => {
            return Note.midi(name + this.oct)
          })
          */
          if (chordNoteName.name) {
            const pcset = {
              tonic: Note.chroma(chordNoteName.name.toString()),
              chroma: PcSet.chroma(chordNotes) || '',
            }
            const chordSet = {
              name: name,
              chord: chordName,
              chordType: chordType,
              note: chordNoteName,
              intervals: intervals,
              pcset: pcset,
              notes: {
                tonic: chordTonic,
                names: chordNotes,
                rest: chordNotes.filter((value: number) => {
                  return value !== this.midi
                }),
              },
            }
            return chordSet
          }
        } else {
          return {}
        }
      })
    },
    beauty (note: any) {
      return note.replace(/#|b/g, (v: any) => {
        if (v === '#') return '♯'
        if (v === 'b') return '♭'
      })
    },
    polarToCart (cX: any, cY: any, r: any, degAng: any) {
      return polarToCartesian(cX, cY, r, degAng)
    },
    midiScaleUp (ms: any) {
      const midiScale: any = []
      ms.map((note: any, index: any, notes: any) => {
        const midiNext = Note.midi(note + this.oct)
        const midiPrev = Note.midi(notes[index - 1] + this.oct)
        // @ts-ignore
        if (midiNext < midiPrev || midiNext < midiScale[index - 1]) {
          // @ts-ignore
          midiScale.push(midiNext + 12)
        } else {
          midiScale.push(midiNext)
        }
      })
      return midiScale
    },
    midiOrderUp (ms: any) {
      return ms.sort((a: any, b: any) => a - b)
    },
    notesOrder (ms: any) {
      return Array.sort(ms)
    },
    player (chordType: string = '', tonic: string = '') {
      // let intervals = Set.intervals(this.mode)
      // let setchroma = PcSet.chroma(intervals)
      // let Set = this.$options.name === 'Scale' ? Scale : Chord
      // let notes = Set.notes(this.tonic, this.mode)
      const chordIntervals = Chord.props(chordType).intervals

      const chordTonic = tonic !== '' ? tonic : this.tonic

      // let chroma = PcSet.chroma(notes)
      if (!this.piano) return
      const notes =
        this.$options.name === undefined
          ? [this.tonic]
          : chordType === ''
            ? buildScale(this.tonic, this.intervals)
            : buildChord(chordTonic, chordIntervals)
      // Convert to events
      const events = notes.map((note: any, i: number) => ({
        time: chordType === '' ? i * 0.5 : 0,
        note,
      }))
      this.piano.stop(ac.currentTime)
      this.piano.schedule(ac.currentTime, events)
    },
    // @ts-ignore
    loadPiano (Soundfont: any, instrument = this.instrument) {
      return Soundfont.instrument(ac, instrument).then((inst: any) => {
        console.log('Piano loaded!')
        this.piano = inst
      })
    },
    changeInstrument (event: any) {
      this.loadPiano(Soundfont, this.instrument)
    },
    removeFromArray (scaleArr: any, itemVal: number | string) {
      const index = scaleArr.indexOf(itemVal)
      if (index > -1) {
        scaleArr.splice(index, 1)
      }
      return scaleArr
    },
  },
})
