/*
 * Color Operations
 */

export function throttle (fn: any, mustRun: number = 60) {
  let previous: number | Date
  return function () {
    const now = new Date()
    // const context = this
    const args = arguments
    if (!previous) {
      previous = 0
    }
    const remaining = Number(now) - Number(previous)
    if (mustRun && remaining >= mustRun) {
      fn.apply(args)
      previous = now
    }
  }
}

export function isInSight (el: any) {
  const bound = el.getBoundingClientRect()
  const clientHeight = window.innerHeight
  return bound.top <= clientHeight + 100
}

interface UserELement extends Element {
  isShow: boolean; 
}

export function checkInSightInit (operate: any) {
  return () => {
    const imgs = document.querySelectorAll('.js-tab-item')
    let initialEl: UserELement[] = []
    // Array.from convierte un objeto iterable en un array.
    // imgs: typeof->NodeList
    Array.from(imgs).forEach((el: any) => {
      el.isShow = false // reset show state
      if (!el.isShow && isInSight(el)) {
        initialEl.push(el)
        el.isShow = true
      }
    })
    operate(initialEl, true)
  }
}

export function checkInSight (operate: any) {
  return () => {
    const imgs = document.querySelectorAll('.js-tab-item')
    Array.from(imgs).forEach((el: any) => {
      if (!el.isShow && isInSight(el)) {
        operate(el)
        el.isShow = true
      }
    })
  }
}

export function clipboardCopy (text: string) {
  // Use the Async Clipboard API when available
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text)
  }

  // ...Otherwise, use document.execCommand() fallback

  // Put the text to copy into a <span>
  var span = document.createElement('span')
  span.textContent = text

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre'

  // An <iframe> isolates the <span> from the page's styles
  var iframe = document.createElement('iframe')
  // @ts-ignore
  iframe.sandbox = 'allow-same-origin'

  // Add the <iframe> to the page
  document.body.appendChild(iframe)
  var win = iframe.contentWindow

  // Add the <span> to the <iframe>
  // @ts-ignore
  win.document.body.appendChild(span)

  // Get a Selection object representing the range of text selected by the user
  // @ts-ignore
  var selection = win.getSelection()

  // Fallback for Firefox which fails to get a selection from an <iframe>
  if (!selection) {
    win = window
    selection = win.getSelection()
    document.body.appendChild(span)
  }

  // @ts-ignore 
  var range = win.document.createRange()
  // @ts-ignore
  selection.removeAllRanges()
  // @ts-ignore 
  range.selectNode(span)
  // @ts-ignore 
  selection.addRange(range)

  var success = false
  try {
    // @ts-ignore 
    success = win.document.execCommand('copy')
  } catch (err) {}

  // @ts-ignore 
  selection.removeAllRanges()
  // @ts-ignore 
  win.document.body.removeChild(span)
  document.body.removeChild(iframe)

  // The Async Clipboard API returns a promise that may reject with `undefined` so we
  // match that here for consistency.
  return success
    ? Promise.resolve()
    : Promise.reject() // eslint-disable-line prefer-promise-reject-errors
}

export function whatColor (rgb: any) {
  let r = parseInt(rgb.slice(0, 2), 16)
  let g = parseInt(rgb.slice(2, 4), 16)
  let b = parseInt(rgb.slice(4, 6), 16)
  var min = Math.min(r, g, b)
  var max = Math.max(r, g, b)
  var diff = max - min
  var h = 0
  if (diff !== 0) {
    h =
      (r === max
        ? (g - b) / diff
        : g === max
          ? 2 + (b - r) / diff
          : 4 + (r - g) / diff) * 60
  }
  if ((r + g + b) / 3 > 220) {
    return 'white'
  } else if ((r + g + b) / 3 < 30) {
    return 'black'
  } else if (h >= 330 || h <= 30) {
    return 'red'
  } else if (h >= 30 && h <= 90) {
    return 'yellow'
  } else if (h >= 90 && h <= 150) {
    return 'green'
  } else if (h >= 150 && h <= 210) {
    return 'cyan'
  } else if (h >= 210 && h <= 270) {
    return 'blue'
  } else if (h >= 270 && h <= 330) {
    return 'purple'
  }
}

export function calFontColor (rgb: any) {
  if (!rgb) return
  let r = parseInt(rgb.slice(0, 2), 16)
  let g = parseInt(rgb.slice(2, 4), 16)
  let b = parseInt(rgb.slice(4, 6), 16)
  if ((r + g + b) / 3 < 128) {
    return 'bright'
  } else {
    return 'dark'
  }
}

export function hex2rgb (hex: any) {
  if (!hex) return
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)
  return [r, g, b]
}

/*
 * Tonal Operations
 */

export function polarToCartesian (centerX: any, centerY: any, radius: any, angleInDegrees: any) {
  let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians)),
  }
}

/*
opts = {
  cx              <-- center x
  cy              <-- center y
  radius          <-- circle radius
  start_angle     <-- start angle in degrees
  end_angle       <-- end angle in degrees
};
*/

/*
 * sector
 */

export function drawSector (cx = 200, cy = 200, radius = 200, degStart = 0, degEnd = 120) {
  let opts = {
    cx: cx,
    cy: cy,
    radius: radius,
    start_angle: degStart,
    end_angle: degEnd,
  }

  let start = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.end_angle)
  let end = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.start_angle)
  let largeArcFlag = opts.end_angle - opts.start_angle <= 180 ? '0' : '1'
  let d = [
    'M', start.x, start.y,
    'A', opts.radius, opts.radius, 0, largeArcFlag, 0, end.x, end.y,
    'L', opts.cx, opts.cy,
    'Z',
  ].join(' ')

  return d

  // document.getElementById('sector').setAttribute('d', d)
  // document.getElementById('sector_d_attr').innerHTML = d
}

/*
 * arc
 */

export function drawArc (cx = 200, cy = 200, radius = 200, degStart = 0, degEnd = 120, thick = 40) {
  let opts = {
    cx: cx,
    cy: cy,
    radius: radius,
    start_angle: degStart,
    end_angle: degEnd,
    thickness: thick,
  }

  let start = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.end_angle)
  let end = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.start_angle)
  let largeArcFlag = opts.end_angle - opts.start_angle <= 180 ? '0' : '1'

  let cutoutRadius = opts.radius - opts.thickness
  let start2 = polarToCartesian(opts.cx, opts.cy, cutoutRadius, opts.end_angle)
  let end2 = polarToCartesian(opts.cx, opts.cy, cutoutRadius, opts.start_angle)

  let d = [
    'M', start.x, start.y,
    'A', opts.radius, opts.radius, 0, largeArcFlag, 0, end.x, end.y,
    'L', opts.cx, opts.cy,
    'Z',
    'M', start2.x, start2.y,
    'A', cutoutRadius, cutoutRadius, 0, largeArcFlag, 0, end2.x, end2.y,
    'L', opts.cx, opts.cy,
    'Z',
  ].join(' ')

  return d

  // document.getElementById('arc').setAttribute('d', d)
  // document.getElementById('arc_d_attr').innerHTML = d
}
