

import { defineComponent } from 'vue'
import { drawArc } from '../util'
import wrap from 'classcat'

import { emitter } from '../main'

export default defineComponent({
  name: 'ChromaticKey',
  props: {
    chroma: {
      type: Number,
      default: 0,
    },
    kind: {
      type: String,
      default: 'white',
    },
    ind: {
      type: Number,
      default: 0,
    },
    oct: {
      type: Number,
      default: 0,
    },
    pcset: {
      type: Object,
      default: () => {},
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    names: {
      type: Object,
      default: () => {},
    },
    x: {
      type: Number,
      default: 0,
    },
    rad: {
      type: Number,
      default: 0,
    },
    rx: {
      type: Number,
      default: 0,
    },
    ry: {
      type: Number,
      default: 0,
    },
    bpos: {
      type: Array,
      default: () => [],
    },
    WHITE_WIDTH: {
      type: Number,
      default: 40,
    },
    WHITE_HEIGHT: {
      type: Number,
      default: 150,
    },
    BLACK_WIDTH: {
      type: Number,
      default: 22,
    },
    BLACK_HEIGHT: {
      type: Number,
      default: 90,
    },
  },
  data () {
    return {
      active: false,
      thick: 60,
    }
  },
  computed: {
    className (): any {
      return this.getKeyTypes() + ' ' + (this.active ? 'active' : '')
    },
    classTextName (): any {
      return this.getTextKeyTypes() + ' ' + (this.active ? 'active' : '')
    },
    midi (): any {
      return (this.oct + 1) * 12 + this.chroma
    },
    noteName (): any {
      return this.names.scale[this.chroma]
    },
    /*
    offset (): any {
      return this.isWhite()
        ? this.ind * this.WHITE_WIDTH
        : this.WHITE_WIDTH * this.bpos[this.ind] - this.BLACK_WIDTH / 2
    },
    */
  },
  methods: {
    onClick (e: any): void{
      console.log('event', e.target)
    },
    isWhite (): boolean {
      return (this.kind === 'white')
    },
    getKeyWidth (): any {
      return this.isWhite() ? this.WHITE_WIDTH : this.BLACK_WIDTH
    },
    getKeyHeight (): any {
      return this.isWhite() ? this.WHITE_HEIGHT : this.BLACK_HEIGHT
    },
    drawChroma (): any {
      let sectorDeg = (360 / 12)
      // let hDeg = sectorDeg
      // let off = 10
      let rotation = 0
      return this.isWhite()
        ? drawArc(0, 0, this.rad - this.thick, this.chroma * sectorDeg + rotation, (this.chroma + 1) * sectorDeg + rotation, this.thick)
        : drawArc(0, 0, this.rad - this.thick, this.chroma * sectorDeg + rotation, (this.chroma + 1) * sectorDeg + rotation, this.thick)
    },
    textMove (): any {
      const sectorDeg = (360 / 12)
      // let hDeg = 0
      const offset = -72
      const rotation = (this.chroma * sectorDeg) + offset
      return this.isWhite()
        ? `rotate( ${rotation} )`
        : `rotate( ${rotation} )`
    },
    tx (): any {
      return this.isWhite()
        ? 130
        : 130
    },
    rotateGroup (): any {
      let sectorDeg = (360 / 7)
      let hDeg = sectorDeg / 2
      let rot
      if (this.pcset.tonic === this.chroma) {
        rot = hDeg
      } else {
        rot = 0
      }
      return 'rotate(' + rot + ')'
    },
    pianoNoteOn (e: any): any {
      e.preventDefault()
      if (this.active === true) {
        this.active = false
      } else {
        this.active = false
      }
      // w/ emmit
      emitter.emit('tonicChange', this.midi)
      // OLD Vue3
      // this.$root.$emit('tonicChange', this.midi)
    },
    getKeyTypes (): any {
      return wrap([
        'piano-key',
        this.kind,
        {
          active: this.notes.names.find((el: any) => { return (el === this.midi) }),
          tonics: this.pcset.tonic === this.chroma,
          tonic: this.notes.tonic === this.midi,
        },
      ])
    },
    getTextKeyTypes (): any {
      return wrap([
        'piano-key-text',
        this.kind,
        {
          active: this.notes.names.find((el: any) => { return (el === this.midi) }),
          tonics: this.pcset.tonic === this.chroma,
          tonic: this.notes.tonic === this.midi,
        },
      ])
    },
  },
})
