

// RotoKey
// import RotoKey from '@/components/RotoKey.vue'

const WHITES = [0, 2, 4, 5, 7, 9, 11]
const BLACKS = [1, 3, 6, 8, 10]
const BPOS = [1, 2, 4, 5, 6]

import { defineComponent } from 'vue'
import ChromaticKey from './ChromaticKey.vue'
import wrap from 'classcat'

import { emitter } from '../main'

export default defineComponent({
  name: 'ChromaticOctave',
  components: {
    ChromaticKey,
  },
  props: {
    white: {
      type: String,
      default: 'white',
    },
    black: {
      type: String,
      default: 'black',
    },
    oct: {
      type: Number,
      default: 0,
    },
    x: {
      type: Number,
      default: 0,
    },
    rad: {
      type: Number,
      default: 0,
    },
    pcset: {
      type: Object,
      default: () => {},
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    names: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      whites: WHITES,
      blacks: BLACKS,
      bpos: BPOS,
    }
  },
  computed: {
    fifths () {
      return [ 'C', 'G', 'D', 'A', 'E', 'B', 'F#' ]
    },
  },
  methods: {
    onClick (e: any): void {
      console.log('event', e.target)
    },
    toggleActive (e: any): void {
      // console.log('event', e.target)
      let elements = document.querySelectorAll('.card')
      /*
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('active')
      })
      */
      elements.forEach((el, i) => {
        el.classList.remove('active')
      })
      e.target.parentNode.parentNode.classList.add('active')
    },
  },
})
