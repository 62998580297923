import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import mitt from 'mitt'

// import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import 'normalize.css'
import './registerServiceWorker'
import './global.scss'


const messages = {
  es: {
    message: {
      value: 'Hola Español.',
    },
  },
  en: {
    message: {
      value: 'Hello English.',
    },
  },
}

const i18n = createI18n({
  locale: 'es',
  messages,
})

const emitter:any = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app
  .use(router)
  .use(i18n)
  .mount('#app')

export {
  app,
  emitter,
}

