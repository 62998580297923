<template>
  <g
    :id="`octave-${oct}`"
  >
    <template
      v-for="(chroma, i) in whites"
      :key="chroma"
    >
      <Key
        :chroma="chroma"
        :ind="i"
        :oct="oct"
        :x="x"
        :pcset="pcset"
        :note="note"
        :notes="notes"
        :bpos="bpos"
        :kind="white"
        :chord-type="chordType"
        :ry="8"
        :rx="8"
      />
    </template>
    <template
      v-for="(chroma, i) in blacks"
      :key="chroma"
    >
      <Key
        :chroma="chroma"
        :ind="i"
        :oct="oct"
        :x="x"
        :pcset="pcset"
        :note="note"
        :notes="notes"
        :bpos="bpos"
        :kind="black"
        :chord-type="chordType"
        :ry="4"
        :rx="4"
      />
    </template>
  </g>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import Key from '../components/Key.vue'

const WHITES = [0, 2, 4, 5, 7, 9, 11]
const BLACKS = [1, 3, 6, 8, 10]
const BPOS = [1, 2, 4, 5, 6]

export default defineComponent({
  name: 'Octave',
  components: {
    Key,
  },
  props: {
    white: {
      type: String,
      default: 'white',
    },
    black: {
      type: String,
      default: 'black',
    },
    oct: {
      type: Number,
      default: 0,
    },
    x: {
      type: Number,
      default: 0,
    },
    pcset: {
      type: Object,
      default: () => {},
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    chordType: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      whites: WHITES,
      blacks: BLACKS,
      bpos: BPOS,
    }
  },
  methods: {
    onClick (e: any): void {
      console.log('event', e.target)
    },
    toggleActive (e: any): void {
      // console.log('event', e.target)
      const elements = document.querySelectorAll('.card')
      /*
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('active')
      })
      */
      elements.forEach((el, i) => {
        el.classList.remove('active')
      })
      e.target.parentNode.parentNode.classList.add('active')
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/mixin.scss';

</style>
