<template>
  <div
    class="Piano"
  >
    <svg
      :width="width"
      :viewBox="`0 0 ${viewWidth} ${keyHeight}`"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <template
          v-for="(el, i) in octs"
          :key="`oct-${el}`"
        >
          <Octave
            :data-key="`oct-${el}`"
            :oct="el"
            :x="i * 7 * keyWidth"
            :pcset="pcset"
            :note="note"
            :notes="notes"
            :chord-type="chordType"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Array, Note } from 'tonal'

import Octave from '../components/Octave.vue'

export default defineComponent({
  name: 'PianoKeyboard',
  components: {
    Octave,
  },
  props: {
    keyWidth: {
      type: Number,
      default: 40,
    },
    keyHeight: {
      type: Number,
      default: 150,
    },
    className: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    tonic: {
      type: String,
      default: 'C4',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    minOct: {
      type: Number,
      default: 3,
    },
    maxOct: {
      type: Number,
      default: 6,
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    pcset: {
      type: Object,
      default: () => {},
    },
    chordType: {
      type: String,
      default: '',
    },
  },
  computed: {
    octs (): any {
      return Array.range(this.minOct, this.maxOct)
    },
    viewWidth (): number {
      return this.octs.length * 7 * this.keyWidth
    },
    newnotes (): object {
      return {
        tonic: this.tonic,
        names: (this.notes || []).reduce((index: any, note: number) => {
          const midiNote: number = Number(Note.midi(note))
          index[midiNote] = note
          return index
        }, {}),
      }
    },
  },
  methods: {
    handleClick (e: any): void {
      // e.preventDefault()
      // emitter.emit('tonicChange', (this.midi, this.notes.names[this.midi]))
      // this.$emit('tonicChange', (this.midi, this.notes.names[this.midi]))
    },
    toggleActive (e: any): void {
      // console.log('event', e.target)
      const elements = document.querySelectorAll('.card')
      /*
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('active')
      })
      */
      elements.forEach((el, i) => {
        el.classList.remove('active')
      })
      e.target.parentNode.parentNode.classList.add('active')
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/mixin.scss';

svg {
  margin: 0 auto;
}
</style>
