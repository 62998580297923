import { createWebHistory, createRouter } from 'vue-router'
// import Home from './views/Home.vue'
// import Key from './views/Key.vue'
import Scale from './views/Scale.vue'

const routes = [
  {
    path: '/',
    redirect: '/scale/C3/major',
  },
  {
    path: '/scale/:n/:m',
    name: 'scale',
    component: Scale,
  },
  /*
  {
    path: '/key/:n/:m',
    name: 'key',
    component: Key,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/About.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: 404,
  },
  */
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
