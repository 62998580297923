<template>
  <div
    class="Piano"
  >
    <svg
      :width="width"
      :viewBox="`0 0 ${viewWidth} ${viewHeight}`"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        :transform="radOffset">
        <template v-for="(octave, i) in octs" :key="`oct-${octave}`">
          <ChromaticOctave
            :data-key="`oct-${octave}`"
            :oct="octave"
            :x="i * 7 * KEY_WIDTH"
            :pcset="pcset"
            :note="note"
            :notes="notes"
            :names="names"
            :rad="rad"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">

import { Array, Note } from 'tonal'

import { defineComponent } from 'vue'
import ChromaticOctave from './ChromaticOctave.vue'
import wrap from 'classcat'

import { emitter } from '../main'

export default defineComponent({
  name: 'ChromaticWheel',
  components: {
    ChromaticOctave,
  },
  props: {
    viewWidth: {
      type: Number,
      default: 360,
    },
    viewHeight: {
      type: Number,
      default: 360,
    },
    KEY_WIDTH: {
      type: Number,
      default: 40,
    },
    KEY_HEIGHT: {
      type: Number,
      default: 150,
    },
    className: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    tonic: {
      type: String,
      default: 'C4',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    minOct: {
      type: Number,
      default: 3,
    },
    maxOct: {
      type: Number,
      default: 6,
    },
    note: {
      type: Object,
      default: () => {},
    },
    notes: {
      type: Object,
      default: () => {},
    },
    names: {
      type: Object,
      default: () => {},
    },
    pcset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    octs (): any {
      return Array.range(this.minOct, this.maxOct)
    },
    rad (): any {
      return this.viewWidth / 2
    },
    radOffset (): any {
      // return 'rotate(-' + (360 * this.octs / 14) + ') translate(0,0)'
      return 'translate(' + (this.viewWidth / 2) + ',' + (this.viewWidth / 2) + ') rotate(-' + (360 / 14) + ')'
    },
    /*
    newnotes (): any {
      return {
        tonic: this.tonic,
        names: (this.notes || []).reduce((index: number, note: string | number) => {
          const midiNote: number = Number(Note.midi(note))
          index[midiNote] = note
          return index
        }, {}),
      }
    },
    */
  },
  methods: {
    handleClick (e: any): any {
      // e.preventDefault()
      // emitter.emit('tonicChange', (this.midi, this.notes.names[this.midi]))
      // this.$emit('tonicChange', (this.midi, this.notes.names[this.midi]))
    },
    toggleActive (e: any): void {
      // console.log('event', e.target)
      let elements = document.querySelectorAll('.card')
      /*
      Array.prototype.forEach.call(elements, function (el, i) {
        el.classList.remove('active')
      })
      */
      elements.forEach((el, i) => {
        el.classList.remove('active')
      })
      e.target.parentNode.parentNode.classList.add('active')
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/mixin.scss';

.dot {
  fill: $mainbg;
}
</style>
